import React, { PropsWithChildren } from 'react'
import { Logo, ChevronLeftIcon } from '@upper/icons'

type LayoutProps = {
    children: React.ReactNode
    appName?: string
    description?: string,
    showNoCredentials?: boolean
}


export default function CredentialsLayout({ children, appName, description, showNoCredentials } : PropsWithChildren<LayoutProps>) {

    const hasDescription = description && description !== ''
    return (
		<div className="flex flex-col min-h-screen md:relative md:overflow-hidden md:bg-gray-lightest">
            
            <div className="flex-1 mt-16 mb-12 md:relative md:flex md:items-start md:mt-48 md:px-24 md:space-x-8">
                <div className="max-w-md px-8 mx-auto md:w-[270px] md:px-0 md:mx-0 md:pt-12">
                    <AppLogo appName={appName} />
                    <p className="mt-5 text-sm text-gray-dark md:mt-12">
                    {description ||
                    'UPPER is a closed network of top vetted freelancers in engineering, design and product management.'}
                    </p>
                    <div className="hidden mt-5 md:block">
                    <a
                        href="https://upper.co/"
                        className="inline-flex items-center text-sm underline text-blue hover:no-underline"
                    >
                        <ChevronLeftIcon className="w-4 h-4 mr-2" />
                        back to upper
                    </a>
                    </div>
                </div>
                <div className="max-w-md px-8 mx-auto mt-16 md:mt-0 md:w-[370px] md:px-0 md:mx-0 md:overflow-hidden md:bg-white md:rounded-md md:shadow-lg">
                    <div className="md:px-8 md:py-12">{children}</div>

                    { showNoCredentials && (
                        <div className="hidden p-8 bg-gray-lightest md:block">
                            <p>
                                <strong>No credentials?</strong> Please{' '}
                                <a
                                href="mailto:contact@upper.co"
                                className="underline text-blue hover:no-underline"
                                >
                                contact us
                                </a>
                            </p>
                        </div>
                    )}

                </div>

            </div>

		</div>
    )
}

function AppLogo({ appName }: { appName?: string }) {
    return (
      <div className="flex align-text-top">
        <Logo className="w-[98px] h-auto text-blue" />
        {appName && (
          <h4 className="ml-2 -mt-1 text-xs font-bold tracking-widest uppercase text-blue">
            {appName}
          </h4>
        )}
      </div>
    )
  }
  
  
import { useAuth } from '@upper/auth'
import { FormikSubmitButton, FormikTextField } from '@upper/formik'
import { Form, Formik } from 'formik'
import Head from 'next/head'
import Link from 'next/link'
import { ReactNode, useCallback, useState } from 'react'
import * as Yup from 'yup'
import CredentialsLayout from '../components/layout/CredentialsLayout'
// import RestAPI from '../rest/API';

interface LoginFormValues {
  email: string
  password: string
}

export default function Login() {
  const initialValues: LoginFormValues = {
    email: '',
    password: '',
  }

  const [loginError, setLoginError] = useState<null | string>(null)

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false)

  const { login } = useAuth()

  const handleLoginSubmit = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      setLoginError(null)
      try {
        await login({ email, password })
      } catch (e: any) {
        setLoginError(e?.data?.message)
      }
      setIsSubmiting(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [login]
  )

  return (
    <>
      <Head>
        <title>Login - Upper Studio</title>
      </Head>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required('Required'),
            password: Yup.string().required('Required'),
          })}
          onSubmit={async (values: LoginFormValues) => {
            if (isSubmiting) {
              return
            }
            setIsSubmiting(true)
            handleLoginSubmit({
              email: values?.email,
              password: values?.password,
            })
          }}
        >
          <Form>
            {loginError && <p className="text-red p-3">{loginError}</p>}
            <div className="space-y-7">
              <FormikTextField name="email" placeholder="Email" />
              <div className="relative">
                <FormikTextField
                  name="password"
                  type="password"
                  placeholder="Password"
                  extra={
                    <Link
                      href="/init-reset-password"
                      className="text-gray whitespace-nowrap bg-white p-1 text-sm underline hover:no-underline"
                    >
                      Forgot password?
                    </Link>
                  }
                />
              </div>
              <FormikSubmitButton fullWidth>Login</FormikSubmitButton>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  )
}

Login.redirectAuthenticatedTo = '/'

Login.getLayout = function getLayout(page: ReactNode) {
  return (
    <CredentialsLayout appName="Studio" showNoCredentials>
      {page}
    </CredentialsLayout>
  )
}
